@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400&display=swap');

:root { scroll-behavior: auto !important; } /* Fix react-scroll bug caused by Bootstrap */
a { cursor: pointer; }                      /* Fix react-scroll links not showing pointer cursor on hover */

body {
  background-color: #282A36;
}

.section {
  padding: 10vh 0;
}

.content {
  max-width: 1080px;
  width: 95%;
}

/* Typography */
* {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 3.2rem; /* Fallback font size */
  font-size: 4.5vw; /* Fallback font size */
  font-size: clamp(2rem, 4.75vw, 3.5rem);
  font-weight: 900;
  display: inline;
}

h2 {
  font-size: 2.5rem; /* Fallback font size */
  font-size: 3.5vw; /* Fallback font size */
  font-size: clamp(1.1rem, 3.6vw, 2.5rem);
  font-weight: 400;
  display: inline;
}

h3 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.75;
  display: inline;
}

p {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.75;
  color: white;
}

.skills-text {
  color: white;
  font-weight: 500;
}

.contact-links {
  display: inline-block;
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  list-style-type: none;
  padding: 0;
  line-height: 1.75;
}
.contact-links a,
.contact-links a:visited {
  text-decoration: none;
  color: white;
}
.contact-links a:hover {
  color: #7367F0;
}

.footer-text {
  width: 100%;
  padding: 5vh 0;
  position: absolute;
  bottom: 0;
  color: white;
  text-align: center;
}

/* Pictures */
.profile {
  border-style: inset;
  border-color: #003262;
  border-width: 5px;
  border-radius: 2%;
  max-width: 512px;
}

@media (max-width: 767.98px) {
  .profile {
    display: block;
    width: 90%;
    margin: auto;
  }
}

.svgicon {
  width: 85px;
  height: 85px;
  display: block;
  margin: auto;

          filter: gray; /* IE6-9 compatibility */
  -webkit-filter: grayscale(100%);
     -moz-filter: grayscale(100%);
       -o-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
          filter: grayscale(100%) brightness(110%);
}

.svgicon:hover {
  filter: none;
}

/* Navigation Bar */
.navbar {
  background-color: #282A36;
}

.navbar-light .navbar-nav .nav-link {
  color: #FBFBFB;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #69FF97;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #282A36;
  background-color: grey; /* Fallback background */
  background-color: transparent;
  background-image: linear-gradient(45deg, #00E4FF, #69FF97);
}

/* Header Gradients */
.blue-gradient {
  background-color: grey; /* Fallback font color */
  background-image: linear-gradient(15deg, #736EFE, #5EFCE8);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.orange-gradient {
  background-color: grey; /* Fallback font color */
  background-image: linear-gradient(20deg, #FD6E6A, #FFC600);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.teal-gradient {
  background-color: grey; /* Fallback font color */
  background-image: linear-gradient(20deg, #00E4FF, #69FF97);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.purple-gradient {
  background-color: grey; /* Fallback font color */
  background-image: linear-gradient(15deg, #7367F0, #CE9FFC);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.green-gradient {
  background-color: grey; /* Fallback font color */
  background-image: linear-gradient(15deg, #28C76F, #81FBB8);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

/* Section Backgrounds */
#home {
  padding-top: 35vh;
  background-color: #FBFBFB;
  background-image: url("media/background-streaks.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 100%;
}

#about {
  background-color: #282A36;
}

#skills {
  background-color: #202020;
}
#skills h4 {
  color: white;
}

#projects {
  background-color: #282A36;
}

#contact {
  background-color: #202020;
  background-image: url("media/background-wave.svg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

/* Cards */
.project-card {
  width: 20rem;
  min-width: 45%;
  max-width: 90%;
  background-color: #202020; /* Fallback background */
  /*border-image: linear-gradient(45deg, #00E4FF, #69FF97) 1;*/
  background: linear-gradient(#202020, #202020) padding-box,
              linear-gradient(45deg, #00E4FF, #69FF97) border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  color: #00E4FF;
  line-height: 1;
}

.project-card .card-body {
  padding-bottom: 0.5rem;
}

.project-card p {
  line-height: 1.25;
}

.project-card .card-footer {
  border-top: 0;
  padding-bottom: 1rem;
}

/* Button */
.btn-teal-gradient {
  background: #00E4FF linear-gradient(45deg, #00E4FF, #69FF97);
  border-color: #00CFE6;
  color: #282A36;
}

.btn-teal-gradient:hover {
  background: linear-gradient(45deg, rgba(0, 228, 255, 0.8),
                                     rgba(105, 255, 151, 0.8));
  color: #282A36;
}

.btn-teal-gradient:focus,
.btn-teal-gradient.focus {
  box-shadow: none;
}

.btn-teal {
  border-color: #5EFCE8;
  color: #5EFCE8;
}

.btn-teal:hover {
  background-color: #5EFCE8;
  color: #282A36;
}

.btn-teal:focus,
.btn-teal.focus {
  box-shadow: none;
}

/* Badges */
.badge-neutral {
  background-color: #696969;
}

.badge-purple {
  background-color: #9457eb;
}

.badge-blue {
  background-color: #4169E1;
}

.badge-yellow {
  background-color: #FFC600;
  color: black;
}

.badge-red {
  background-color: #B22222;
}

.badge-outlined {
  background-color: transparent;
  outline: 1px solid white;
}

/* Terminal */
#terminal {
  margin: 10vh auto 0;
  width: 80%;
  min-width: fit-content;
  max-width: 1080px;
}

#bar {
  display: flex;
  height: 25px;
  background-color: #E4E3E5;
  float: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#bar-buttons {
  display: flex;  
  align-items: center;
}

#button-red {
  background-color: #F96256;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 0 0 0 1vw;
  position: relative;
}

#button-yellow {
  background-color: #FDBC3D;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 0 0 0 0.5vw;
  position: relative;
}

#button-green {
  background-color: #33C948;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 0 0 0 0.5vw;
  position: relative;
}

#screen {
  background-color: #212122;
  border-radius: 0 0 4px 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 20px 100px rgba(50, 50, 93, .15),
              0 15px 35px rgba(50, 50, 93, .2),
              0 5px 15px rgba(0, 0, 0, .12);
}

#screen .command {
  margin: 0 10px 0 0;
  color: #FFFFFF;
  font-family: 'Roboto Mono', monospace;
  font-size: 13px;
  text-align: left;
  position: static;
  padding-left: 30px;
}

#screen .command::before {
  content: "> ";
}

#screen .command.nested::before {
  content: ">>> "
}

#screen .response {
  margin: 0 10px 20px 0;
  color: #4CD964;
  font-family: 'Roboto Mono', monospace;
  font-size: 13px;
  text-align: left;
  position: static;
  padding-left: 30px;
}

#screen .array::before {
  content: "[";
}

#screen .array::after {
  content: "]";
}

#screen a {
  background-color: rgba(104, 104, 104, 0.4);
  color: #4CD964;
  font-family: 'Roboto Mono', monospace;
  font-size: 13px;
}

#screen a:hover {
  color: #FFFFFF;
}

#screen .cursor {
  background: hsla(0, 0%, 97%, .65);
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
